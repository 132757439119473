//growthHub Styling
$success-color: #FFE81E;
$primary-color: #000;
$white: #FFF;
$java: #17c671;

//overrides
$success: $success-color;           //lot of things, buttons for example
$primary: $primary-color ;          //

$accent-color: $success-color;      // side-bar:hover
$nav-background-color: $primary-color;
$nav-header-color: $success-color;
$nav-border-color: $white;

a {
  color: $primary-color!important;
}


.btn-primary {
  color: $white!important;
  text-decoration: none!important;
}

.btn-primary:hover {
  color: $white!important;
  text-decoration: none!important;
}


a:hover {
  text-decoration: underline $primary-color!important;

  //box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(255, 232, 30, 0.25);
}

.main-sidebar .nav .nav-item.active,
.main-sidebar .nav .nav-item .nav-link.active,
.main-sidebar .nav .nav-item:hover,
.main-sidebar .nav .nav-item .nav-link:hover
//.nav-wrapper
{
  background-color: $nav-background-color!important;
  color: $success-color!important;
  //font-weight: 600;
  //letter-spacing: 0.1em;
}

.primary-text {
  color: $primary-color!important;
}

.main-sidebar__nav-title {
  color: $primary-color!important;
}

.badge-info {
}

.badge-outline-info {
  color: black!important;
}

.badge-success {
  color: black;
}

.rbc-calendar .rbc-event{
  color: $primary-color;
}

.badge-outline-warning,
.badge-outline-success,
.badge-outline-java,
.badge-outline-royal-blue{
  color: $primary-color!important;
}

.badge {
  border: 1px solid rgba(0,0,0,0);
}

$font-size-base: 1.2rem;
$btn-font-size-sm: 0.875rem!important;
$custom-select-font-size-sm: $btn-font-size-sm;
$font-family-poppins-first: 'Montserrat', sans-serif;

$font-family-sans-serif: $font-family-poppins-first;
$font-family-monospace: $font-family-poppins-first;


body,
.nav-link
{
  //font-size: $font-size-base;
  font-family: $font-family-poppins-first!important;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Valera Round', sans-serif;
}

.btn {
  font-size: $btn-font-size-sm;
}

.Link-success {
  color: white!important;
  font-weight: bold!important;
}

.bg-green {
  color: white;
  background-color: $java;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0.9375rem 1.25rem;
}

.pointer {
  cursor: pointer;
}


