/*
 * Shards Dashboards UI Kit v1.0.0
 * Project URL: https://designrevision.com/downloads/shards-dashboards
 * Based on: Shards UI Kit v2.0.1 (https://designrevision.com/downloads/shards)
 * Based on: Bootstrap v4.0.0 (final) (https://getbootstrap.com)
 * Copyright 2017-* DesignRevision (https://designrevision.com)
 * Copyright 2017-* Catalin Vasile (contact@catalin.me)
 * License: SEE THE LICENSE FILE
 */


// Shards variables overrides.
@import "overrides";
@import "growthhub-customs";

// Base Shards UI Kit
@import "../../node_modules/shards-ui/src/scss/shards";

// Core
@import "variables";



// Components
@import "root";
@import "reboot";
@import "buttons";
@import "button-group";
@import "navbar";
@import "badge";
@import "alert";
@import "input-group";
@import "custom-forms";
@import "custom-sliders";
@import "charts";
@import "images";
@import "icons";
@import "dropdown";
@import "card";
@import "utilities";
@import "react-big-calendar";
@import "components/IdeaPipeLine";

// Plugins
@import "plugins/data-tables";
@import "plugins/dropzone";
@import "plugins/tagsinput";
@import "plugins/quill";

// Blocks
@import "blocks/main-navbar";
@import "blocks/main-sidebar";
@import "blocks/main-content";
@import "blocks/main-footer";
@import "blocks/page-header";
@import "blocks/header-navbar";

// Templates
@import "templates/common"; // Required common styles for all pages.
@import "templates/analytics";
@import "templates/ecommerce";
@import "templates/transaction-history"; // Empty, feel free to add your custom styles.
@import "templates/file-manager";
@import "templates/user-profile";
@import "templates/edit-user-profile";
@import "templates/authentication"; // Register, Login, Forgot and Reset Password Templates
@import "templates/blog-overview";
@import "templates/blog-add-new-post";

// Components
@import "components/card-post";
@import "components/error";

// Assets
@import "assets/react-table";
@import "assets/dropzone";
@import "assets/quill";
@import "assets/range-date-picker";
@import "assets/file-manager-list";
@import "assets/react-tagsinput";

// Customs
html, body, #root, .Application, .Auth {
  height: 100%;
}

.react-tagsinput-input {
  width: 100% !important;
}


