// React Table

.ReactTable {
  border: $rt-border !important;

  // Head
  .rt-thead.-header,
  .-pagination {
    box-shadow: $rt-head-box-shadow !important;
  }

  // Body
  .rt-tbody {
    .rt-td {
      font-size: $rt-body-td-font-size !important;
      padding: $rt-body-td-padding !important;
      border-left: $rt-body-td-border-left !important;
      border-bottom: $rt-body-td-border-bottom !important;
      text-align: $rt-body-td-text-align !important;
      display: $rt-body-td-display !important;
      justify-content: $rt-body-td-justify-content !important;
      align-items: $rt-body-td-align-items !important;
    }

    .rt-td:first-of-type {
      border-left: none !important;
    }

    .tr-td {
      border-bottom: none !important;
    }

    .rt-tr-group {
      border: none !important;
    }
  }

  .rt-tr-group:last-of-type .rt-td {
    border-bottom: none !important;
  }

  // Head
  .rt-thead {
    .rt-tr {
      border-bottom: $rt-head-tr-border-bottom !important;
    }

    .rt-th {
      background-color: $rt-head-th-background-color !important;
      font-weight: $rt-head-th-font-weight !important;
      padding: $rt-head-th-padding !important;
      font-size: $rt-head-th-font-size !important;
      border: $rt-head-th-border !important;
    }

    /* Ascending & Descending Sort */
    .rt-th.-sort-asc,
    .rt-td.-sort-asc {
      box-shadow: $rt-head-asc-box-shadow !important;
    }

    .rt-th.-sort-desc,
    .rt-td.-sort-desc {
      box-shadow: $rt-head-desc-box-shadow !important;
    }
  }

  /* Pagination */
  .-pagination {
    font-size: $rt-pagination-font-size !important;
    border: none !important;
    border-top: $rt-pagination-border-top !important;
    padding: $rt-pagination-padding !important;

    .-btn {
      padding: $rt-pagination-btn-padding !important;
      width: $rt-pagination-btn-width !important;
      color: $rt-pagination-btn-color !important;
      font-weight: $rt-pagination-btn-font-weight !important;
    }

    .-pageJump input {
      width: $rt-pagination-jump-input-width !important;
    }

    .-next button {
      margin-left: auto !important;
    }

    button {
      padding: $rt-pagination-button-padding !important;
      background: $rt-pagination-button-background !important;
      border: $rt-pagination-button-border !important;
      border-radius: $rt-pagination-button-border-radius !important;
    }

    button:hover {
      color: $rt-pagination-button-hover-color !important;
      background: $rt-pagination-button-hover-background !important;
    }

    .-btn[disabled],
    .-btn[disabled]:hover {
      background-color: $rt-pagination-btn-disabled-background-color !important;
      color: $rt-pagination-btn-disabled-color !important;
    }
  }
}
